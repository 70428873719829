import React, { Component } from 'react'
import { monitoreosActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import MySelect from '../../../components/select/MySelect';
import { cadenasService, monitoreosService } from '../../../services';
import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),  
    marca: Yup.string()
        .required("Campo requerido"), 
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    item: Yup.string()
        .required("Campo requerido"),
    producto: Yup.string()
        .required("Campo requerido"),
    tamano: Yup.string()
        .required("Campo requerido"),
    mes: Yup.string()
        .required("Campo requerido"),
    precio_normal: Yup.number()
        .required("Campo requerido"),
    precio_oferta: Yup.number()
        .nullable("Campo requerido"),
    precio_promocion: Yup.number()
        .nullable("Campo requerido"),
    promocional: Yup.string()
        .required("Campo requerido"),
    n_a: Yup.number()
        .nullable("Campo requerido"),
    fecha_inicio: Yup.string()
        .required("Campo requerido"),
    fecha_fin: Yup.string()
        .required("Campo requerido"),
    fotografia: Yup.string()
        .nullable("Campo requerido"),
    observaciones: Yup.string()
        .required("Campo requerido"),
});

class MonitoreosForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            monitoreo: {
                cadena: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                promocional: "",
                mes: "",
                precio_normal: 0,
                precio_oferta: 0,
                precio_promocion: 0,
                n_a: 0,
                fecha_inicio: "",
                fecha_fin: "",
                fotografia: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
            meses: [
                { value: 1, label: "Enero" },
                { value: 2, label: "Febrero" },
                { value: 3, label: "Marzo" },
                { value: 4, label: "Abril" },
                { value: 5, label: "Mayo" },
                { value: 6, label: "Junio" },
                { value: 7, label: "Julio" },
                { value: 8, label: "Agosto" },
                { value: 9, label: "Septiembre" },
                { value: 10, label: "Octubre" },
                { value: 11, label: "Nobiembre" },
                { value: 12, label: "Diciembre" },
            ]

        };

        cadenasService.getAll().then(
            response => {
                if(response.status){
                    this.setState({cadenas: response.data})
                }
            }
        );
    }

    componentDidMount(){  
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ 
                    monitoreo: {
                        ...this.state.monitoreo,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }
        if(this.props.id){
            let monitoreo_temp = this.state.monitoreo;
            monitoreosService.getOne(this.props.id).then(
                response => {
                    console.log(response.data)
                    if(response.data.mes !== undefined){
                        for (const result of this.state.meses) {
                            if(response.data.mes === result.value){
                                response.data.mes = result;
                            }
                        }
                    }
                    monitoreo_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({monitoreo: monitoreo_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.monitoreo}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                   
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(monitoreosActions.guardar(values));
                    }else{
                        dispatch(monitoreosActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaño</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="mes">Mes</label>
                                <MySelect
                                    value={values.mes||''}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.mes}
                                    touched={touched.mes}
                                    field={'mes'}
                                    options={this.state.meses}
                                />
                                <ErrorMessage component="div" name="mes" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_normal">Precio Normal</label>
                                <Field type="number" step="0.01" name="precio_normal" value={values.precio_normal || ''} placeholder="0" className={`form-control ${touched.precio_normal && errors.precio_normal ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_normal" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_oferta">Precio de Oferta</label>
                                <Field type="number" step="0.01" name="precio_oferta" value={values.precio_oferta || ''} placeholder="0" className={`form-control ${touched.precio_oferta && errors.precio_oferta ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_oferta" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_promocion">Precio de Promoción</label>
                                <Field type="number" step="0.01" name="precio_promocion" value={values.precio_promocion || ''} placeholder="0" className={`form-control ${touched.precio_promocion && errors.precio_promocion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_promocion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio" value={values.fecha_inicio || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio && errors.fecha_inicio ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio" className="invalid-feedback" />
                                    </div>
                                </div>
                            <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin" value={values.fecha_fin || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin && errors.fecha_fin ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="form-group">
                                <label htmlFor="n_a">N/A</label>
                                <Field type="checkbox"  name="n_a" value={values.n_a || ''} onChange={() => setFieldValue('n_a',(values.n_a === 1) ? 0:1 )} checked={(values.n_a === 0) ? false:true} className={`form-control ${touched.n_a && errors.n_a ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="n_a" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="promocional">Promocionales</label>
                                <Field type="text" name="promocional" value={values.promocional || ''} placeholder="Ingrese la Promocional" className={`form-control ${touched.promocional && errors.promocional ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="promocional" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.monitoreo.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(MonitoreosForm);