import {apiUrl} from '../helpers'
export const vacacionesService = {
    guardar,
    actualizar,
    getAll,
    getMotivos,
    getTipos,
    getLicencias,
    getDias,
    guardarArchivo,
    getArchivo,
    getOne
};

const USER_API_BASE_URL = apiUrl;

function getAll() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/recursoshumanos/v1/vacaciones/todas?';
    var params = {token: user.access_token,empleado_id: user.id};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getMotivos() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Motivos de Vacaciones'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getArchivo(archivo) {
    
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + `api/recursoshumanos/v1/obtener-archivo?`;
    var params = {token: user.access_token, tipo: archivo};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getTipos() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Tipo Acción de Personal'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getLicencias() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + 'api/config/v1/obtener?';
    var params = {token: user.access_token, nombre: 'Tipos de licencia con goce de salario'};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}

function getDias() {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + `api/recursoshumanos/v1/vacaciones/getVacacionesDisponibles/${user.id}?`;
    var params = {token: user.access_token};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}


function getOne(vacaciones) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    var url = USER_API_BASE_URL + `api/recursoshumanos/v1/vacaciones/obtener/${vacaciones}?`;
    var params = {token: user.access_token};
    let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');

    return fetch(url+query, requestOptions).then(handleResponse)
    .then(data => {
        return data;
    });
}
function guardar(formData) {
    let user = JSON.parse(localStorage.getItem('user'));

    let Data = new FormData();
    Data.append('empleado_id',user.id)

    if(formData.acepta_empleado === true){
        Data.append('acepta_empleado',1);

    }else{
        Data.append('acepta_empleado',0);
    }
    if(formData.archivo_extra !== undefined && formData.archivo_extra !== ""){
        Data.append('archivo_extra',formData.archivo_extra);
    }
    if(formData.archivo_firmado !== undefined && formData.archivo_firmado !== ""){
        Data.append('archivo_firmado',formData.archivo_firmado);
    }
    Data.append('comentarios',formData.comentarios);
    Data.append('dias',formData.dias);
    Data.append('fecha_regreso',formData.fecha_regreso);
    Data.append('fecha_salida',formData.fecha_salida);
    Data.append('fecha_fin',formData.fecha_fin);
    Data.append('lugar',formData.lugar);
    
    if(formData.movimiento_id !== undefined && formData.movimiento_id !== ""){
        Data.append('movimiento_id',formData.movimiento_id);
    }

    if(formData.tipo_id !== undefined && formData.tipo_id !== ""){
        Data.append('tipo_id',formData.tipo_id);
    }

    if(formData.tipo_licencia_id !== undefined && formData.tipo_licencia_id !== ""){
        Data.append('tipo_licencia_id',formData.tipo_licencia_id);
    }
    Data.append('otros',formData.otros);
    if(formData.saldra_pais === true){
        Data.append('saldra_pais',1);
    }else{
        Data.append('saldra_pais',0);   
    }


    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };

    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/vacaciones/guardar', requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log('==============RRREEEEESSSSSPPPPOOONNNNNSEEEEEE======================');
            console.log(response);
            console.log('====================================');
            return response;
        });
}

function guardarArchivo(formData) {
    let user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: formData
    };
    
    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/vacaciones/guardarArchivo', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function actualizar(formData, id) {
    let user = JSON.parse(localStorage.getItem('user'));
    let Data = new FormData();
    Data.append('id',id);
    Data.append('empleado_id',user.id);

    if(formData.acepta_empleado === true){
        Data.append('acepta_empleado',1);

    }else{
        Data.append('acepta_empleado',0);
    }
    if(formData.archivo_extra !== null && formData.archivo_extra !== ""){
        Data.append('archivo_extra',formData.archivo_extra);
    }
    if(formData.archivo_firmado !== null && formData.archivo_firmado !== ""){
        Data.append('archivo_firmado',formData.archivo_firmado);
    }
    Data.append('comentarios',formData.comentarios);
    Data.append('dias',formData.dias);
    Data.append('fecha_regreso',formData.fecha_regreso);
    Data.append('fecha_salida',formData.fecha_salida);
    Data.append('fecha_fin',formData.fecha_fin);
    Data.append('lugar',formData.lugar);
    
    if(formData.movimiento_id !== null && formData.movimiento_id !== undefined){
        Data.append('movimiento_id',formData.movimiento_id);
    }

    if(formData.tipo_id !== null && formData.tipo_id !== undefined){
        Data.append('tipo_id',formData.tipo_id);
    }

    if(formData.tipo_licencia_id !== null && formData.tipo_licencia_id !== undefined){
        Data.append('tipo_licencia_id',formData.tipo_licencia_id);
    }
    Data.append('otros',formData.otros);
    
    if(formData.saldra_pais === true){
        Data.append('saldra_pais',1);
    }else{
        Data.append('saldra_pais',0);   
    }

    
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer '+user.access_token},
        body: Data
    };
    
    return fetch(USER_API_BASE_URL + 'api/recursoshumanos/v1/vacaciones/actualizar', requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                return response.message;
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        console.log('====================================');
        console.log(data);
        console.log('====================================');
        return data;
    });
}