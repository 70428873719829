import React, { Component } from 'react'
import { competenciaActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import {  competenciaService } from '../../../services';
import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),    
    marca: Yup.string()
        .required("Campo requerido"), 
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    item: Yup.string()
        .required("Campo requerido"),
    producto: Yup.string()
        .required("Campo requerido"),
    tamano: Yup.string()
        .required("Campo requerido"),
    precio_normal: Yup.number()
        .required("Campo requerido"),
});


class CompetenciaForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            competencia: {
                cadena: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                btl: "",
                incentivo: "",
                exhibicion: "",
                modelo: "",
                n_a: 0,
                precio_normal: 0,
                precio_oferta: 0,
                precio_promocion: 0,
                fecha_inicio_btl: "",
                fecha_fin_btl: "",
                observaciones_btl: "",
                fecha_inicio_exhibicion: "",
                fecha_fin_exhibicion: "",
                observaciones_exhibicion: "",
                fecha_inicio_incentivo: "",
                fecha_fin_incentivo: "",
                observaciones_incentivo: "",
                fotografia: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
        };
    }

    componentDidMount(){  
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({ 
                    competencia: {
                        ...this.state.competencia,
                        latitud: position.coords.latitude, 
                        longitud: position.coords.longitude
                    }
                })
            );
        }
        if(this.props.id){
            let competencia_temp = this.state.competencia;
            competenciaService.getOne(this.props.id).then(
                response => {
                    competencia_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({competencia: competencia_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.competencia}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    console.log(values);
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){
                        dispatch(competenciaActions.guardar(values));
                    }else{
                        dispatch(competenciaActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                    <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaños</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_normal">Precio Normal</label>
                                <Field type="number" step="0.01" name="precio_normal" value={values.precio_normal || ''} placeholder="0" className={`form-control ${touched.precio_normal && errors.precio_normal ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_normal" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_oferta">Precio de Oferta</label>
                                <Field type="number" step="0.01" name="precio_oferta" value={values.precio_oferta || ''} placeholder="0" className={`form-control ${touched.precio_oferta && errors.precio_oferta ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_oferta" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio_promocion">Precio de Promoción</label>
                                <Field type="number" step="0.01" name="precio_promocion" value={values.precio_promocion || ''} placeholder="0" className={`form-control ${touched.precio_promocion && errors.precio_promocion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio_promocion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="n_a">N / A</label>
                                <Field type="checkbox" name="n_a" value={values.n_a || ''} onChange={() => setFieldValue('n_a',(values.n_a === 1) ? 0:1 )} checked={(values.n_a === 0) ? false:true} className={`form-control ${touched.n_a && errors.n_a ? "is-invalid" : "" }`} />
                                {/* <Field type="text" name="n_a" value={values.n_a || ''} placeholder="N / A" className={`form-control ${touched.n_a && errors.n_a ? "is-invalid" : "" }`} /> */}
                                <ErrorMessage component="div" name="n_a" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file" id="fotografia"  accept="image/*" capture="user" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="btl">BTL</label>
                                <Field type="text" name="btl" value={values.btl || ''} placeholder="BTL" className={`form-control ${touched.btl && errors.btl ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="btl" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio_btl">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio_btl" value={values.fecha_inicio_btl || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio_btl && errors.fecha_inicio_btl ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio_btl" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin_btl">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin_btl" value={values.fecha_fin_btl || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin_btl && errors.fecha_fin_btl ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin_btl" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones_btl">Observaciones</label>
                                <Field type="text" name="observaciones_btl" value={values.observaciones_btl || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_btl && errors.observaciones_btl ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_btl" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="exhibicion">Exhibicion</label>
                                <Field type="text" name="exhibicion" value={values.exhibicion || ''} placeholder="Exhibicion" className={`form-control ${touched.exhibicion && errors.exhibicion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="exhibicion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio_exhibicion">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio_exhibicion" value={values.fecha_inicio_exhibicion || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio_exhibicion && errors.fecha_inicio_exhibicion ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio_exhibicion" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin_exhibicion">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin_exhibicion" value={values.fecha_fin_exhibicion || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin_exhibicion && errors.fecha_fin_exhibicion ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin_exhibicion" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones_exhibicion">Observaciones</label>
                                <Field type="text" name="observaciones_exhibicion" value={values.observaciones_exhibicion || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_exhibicion && errors.observaciones_exhibicion ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_exhibicion" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="incentivo">Incentivo</label>
                                <Field type="text" name="incentivo" value={values.incentivo || ''} placeholder="Incentivo" className={`form-control ${touched.incentivo && errors.incentivo ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="incentivo" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio_incentivo">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio_incentivo" value={values.fecha_inicio_incentivo || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio_incentivo && errors.fecha_inicio_incentivo ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio_incentivo" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin_incentivo">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin_incentivo" value={values.fecha_fin_incentivo || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin_incentivo && errors.fecha_fin_incentivo ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin_incentivo" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12">
                            <div className="form-group">
                                <label htmlFor="observaciones_incentivo">Observaciones</label>
                                <Field type="text" name="observaciones_incentivo" value={values.observaciones_incentivo || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones_incentivo && errors.observaciones_incentivo ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones_incentivo" className="invalid-feedback" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(CompetenciaForm);