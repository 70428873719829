import React, { Component } from 'react'
import { btlActions } from '../../../actions';
import { connect } from 'react-redux'
import {apiUrl} from '../../../helpers'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { cadenasService,  btlService } from '../../../services';
import getPermissions from '../../../helpers/permissions';

const LoginSchema = Yup.object().shape({
    cadena: Yup.string()
        .required("Campo requerido"),
    tienda: Yup.string()
        .required("Campo requerido"),   
    categoria: Yup.string()
        .required("Campo requerido"),
    subcategoria: Yup.string()
        .required("Campo requerido"),
    btl: Yup.string()
        .required("Campo requerido"),
    precio: Yup.number()
        .required("Campo requerido"),
    fecha_inicio: Yup.string()
        .required("Campo requerido"),
    fecha_fin: Yup.string()
        .required("Campo requerido"),
    fotografia: Yup.string()
        .nullable("Campo requerido"),
    observaciones: Yup.string()
        .required("Campo requerido"),
});

class BtlForm extends Component{
    
    constructor(props){
        super(props);
        this.state = { 
            btl: {
                cadena: "",
                tienda: "",
                marca: "",
                categoria: "",
                subcategoria: "",
                item: "",
                producto: "",
                tamano: "",
                btl: "",
                precio: 0,
                fecha_inicio: "",
                fecha_fin: "",
                fotografia: "",
                observaciones: "",
                latitud: "",
                longitud: "",
            },
            

        };

        cadenasService.getAll().then(
            response => {
                if(response.status){
                    this.setState({cadenas: response.data})
                }
            }
        );
    }

    componentDidMount(){  
        
        
        const { dispatch } = this.props;
        if (getPermissions(dispatch)){
            navigator.geolocation.getCurrentPosition( 
                position => this.setState({
                    ...this.btl,
                    latitud: position.coords.latitude, 
                    longitud: position.coords.longitude
                })
            );
        }
        if(this.props.id){
            let btl_temp = this.state.btl;
            btlService.getOne(this.props.id).then(
                response => {
                    btl_temp = response.data;
                }
            ).finally(()=>{
                    this.setState({btl: btl_temp});
                }
            );
        }
    }
    render(){
        return (
            <Formik
                initialValues={this.state.btl}
                enableReinitialize={true}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    const { dispatch } = this.props;
                    if(this.props.id === undefined){

                        dispatch(btlActions.guardar(values));
                    }else{
                        dispatch(btlActions.actualizar(values,this.props.id));
                    }
                    setSubmitting(false);
                }}
            >{({ setFieldValue,setFieldTouched, values, touched, errors, isSubmitting }) => (
                <Form>
                    <div className="row">
                    <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="cadena">Cadena</label>
                                <Field type="text" name="cadena" value={values.cadena || ''} placeholder="Ingrese la Cadena" className={`form-control ${touched.cadena && errors.cadena ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="cadena" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tienda">Tienda</label>
                                <Field type="text" name="tienda" value={values.tienda || ''} placeholder="Ingrese la Tienda" className={`form-control ${touched.tienda && errors.tienda ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tienda" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="marca">Marca</label>
                                <Field type="text" name="marca" value={values.marca || ''} placeholder="Ingrese la Marca" className={`form-control ${touched.marca && errors.marca ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="marca" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="categoria">Categoria</label>
                                <Field type="text" name="categoria" value={values.categoria || ''} placeholder="Ingrese la Categoria" className={`form-control ${touched.categoria && errors.categoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="categoria" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="subcategoria">Sub Categoria</label>
                                <Field type="text" name="subcategoria" value={values.subcategoria || ''} placeholder="Ingrese la Sub Categoria" className={`form-control ${touched.subcategoria && errors.subcategoria ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="subcategoria" className="invalid-feedback" />
                            </div>
                        </div> <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="item">Modelo/Item</label>
                                <Field type="text" name="item" value={values.item || ''} placeholder="Ingrese el Modelo/Item" className={`form-control ${touched.item && errors.item ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="item" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="producto">Producto</label>
                                <Field type="text" name="producto" value={values.producto || ''} placeholder="Ingrese el Producto" className={`form-control ${touched.producto && errors.producto ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="producto" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="tamano">Tamaños</label>
                                <Field type="text" name="tamano" value={values.tamano || ''} placeholder="Ingrese el Tamaño" className={`form-control ${touched.tamano && errors.tamano ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="tamano" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="btl">BTL</label>
                                <Field type="text" name="btl" value={values.btl || ''} placeholder="Ingrese el BTL" className={`form-control ${touched.btl && errors.btl ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="btl" className="invalid-feedback" />
                            </div>
                        </div>
                        
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label htmlFor="precio">Precio </label>
                                <Field type="number" step="0.01" name="precio" value={values.precio || ''} placeholder="0" className={`form-control ${touched.precio && errors.precio ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="precio" className="invalid-feedback" />
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <label htmlFor="fecha_vigencia">Fecha de Vigencia</label>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_inicio">Fecha de Inicio</label>
                                        <Field type="date" name="fecha_inicio" value={values.fecha_inicio || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_inicio && errors.fecha_inicio ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_inicio" className="invalid-feedback" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fecha_fin">Fecha de Fin</label>
                                        <Field type="date" name="fecha_fin" value={values.fecha_fin || ''} placeholder="Fecha de Vigencia" className={`form-control ${touched.fecha_fin && errors.fecha_fin ? "is-invalid" : "" }`} />
                                        <ErrorMessage component="div" name="fecha_fin" className="invalid-feedback" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="fotografia">Fotografia</label>
                                <input type="file"  accept="image/*" capture="user" id="fotografia" name="fotografia" onChange={(event) => { setFieldValue("fotografia", event.currentTarget.files[0]);}} className={`form-control ${touched.fotografia && errors.fotografia ? "is-invalid" : "" }`} />
                                {this.state.fotografia!==""?<img src={apiUrl+'storage/uploads/images/'+values.fotografia} className="img-fluid" alt=""></img>:''}
                                <ErrorMessage component="div" name="fotografia" className="invalid-feedback" />
                            </div>
                        </div> 
                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <label htmlFor="observaciones">Observaciones</label>
                                <Field type="text" name="observaciones" value={values.observaciones || ''} placeholder="Observaciones" className={`form-control ${touched.observaciones && errors.observaciones ? "is-invalid" : "" }`} />
                                <ErrorMessage component="div" name="observaciones" className="invalid-feedback" />
                            </div>
                        </div>
                    
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting} > {isSubmitting ? "Guardando..." : "Guardar"} </button>
                        </div>
                    </div>
                </Form>
            )}
            </Formik>
        )
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
    };
}


export default connect(mapStateToProps)(BtlForm);